@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*  Noonnu Korean Font */
@font-face { 
    font-family: 'JSDongkang-Regular'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSDongkang-RegularA1.woff') format('woff'); 
    font-weight: normal; 
    font-style: normal; 
}
@font-face { 
    font-family: 'JSDongkang-Bold'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSDongkang-BoldA1.woff') format('woff'); 
    font-weight: normal;
    font-style: normal; 
}
@font-face { 
    font-family: 'IBMPlexSansKR-Bold'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Bold.woff') format('woff'); 
    font-weight: normal; 
    font-style: normal; 
}
@font-face { 
    font-family: 'IBMPlexSansKR-Medium'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Medium.woff') format('woff'); 
    font-weight: normal; 
    font-style: normal; 
}
@font-face { 
    font-family: 'IBMPlexSansKR-Regular'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff') format('woff'); 
    font-weight: normal; 
    font-style: normal; 
}
@font-face { 
    font-family: 'TDTDTadakTadak'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/TDTDTadakTadak.woff') format('woff'); 
    font-weight: normal; 
    font-style: normal; 
}


/* English Font */
@font-face { 
  font-family: 'NotoSansCJKkr-Black'; 
  src: url(/static/media/NotoSansCJKkr-Black.c09933ce.otf) format('oepntype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Bold'; 
  src: url(/static/media/NotoSansCJKkr-Bold.79d613fb.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-DemiLight'; 
  src: url(/static/media/NotoSansCJKkr-DemiLight.82967d53.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Light'; 
  src: url(/static/media/NotoSansCJKkr-Light.1883dc03.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Medium'; 
  src: url(/static/media/NotoSansCJKkr-Medium.a5950471.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Regular'; 
  src: url(/static/media/NotoSansCJKkr-Regular.19b7d8d4.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Thin'; 
  src: url(/static/media/NotoSansCJKkr-Thin.6a45f53f.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansMonoCJKkr-Bold'; 
  src: url(/static/media/NotoSansMonoCJKkr-Bold.05dc64c8.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansMonoCJKkr-Regular'; 
  src: url(/static/media/NotoSansMonoCJKkr-Regular.0fec43a7.otf) format('opentype'); 
}
@font-face { 
  font-family: 'OSeongandHanEum-Bold'; 
  src: url(/static/media/OSeongandHanEum-Bold.af0dbd62.otf) format('opentype'); 
}
@font-face { 
  font-family: 'OSeongandHanEum-Regular'; 
  src: url(/static/media/OSeongandHanEum-Regular.c57bfcf3.otf) format('opentype'); 
}
@font-face { 
  font-family: 'TmonMonsori'; 
  src: url(/static/media/TmonMonsori.cef36aa5.otf) format('opentype'); 
}
@font-face { 
  font-family: 'TmonTium'; 
  src: url(/static/media/TmonTium.5050b237.ttf) format('truetype'); 
}
@font-face { 
  font-family: 'TradeGothicLTStd'; 
  src: url(/static/media/TradeGothicLTStd.fbb1aa81.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Black'; 
  src: url(/static/media/NotoSansCJKkr-Black.c09933ce.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Bold'; 
  src: url(/static/media/NotoSansCJKkr-Bold.79d613fb.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Light'; 
  src: url(/static/media/NotoSansCJKkr-Light.1883dc03.otf) format('opentype'); 
}
@font-face { 
  font-family: 'NotoSansCJKkr-Regular'; 
  src: url(/static/media/NotoSansCJKkr-Regular.19b7d8d4.otf) format('opentype'); 
}

html, body {
    margin: 0;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-family: 'Bungee Inline', cursive;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, div {
  font-family: 'IBMPlexSansKR-Regular';
}

@media (min-width: 1300px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1300px) {
  html {
    font-size: 12px;
  }
}

.index-page body {
  background-color: #ffffff !important;
}

.signup .error, .index-page .error {
  font-size: 0.8571rem;
  color: #f06293;
}

.index-page .input-wrapper {
  /* width: 23.4286rem; */
  width: 100%;
  margin: 0 auto 1.7143rem;
  padding: 0.7857rem 2.2857rem 2.8571rem;
  border-radius: 0.8571rem;
  box-shadow: 0 0 0.3571rem 0 rgba(175, 175, 175, 0.29);
}

.index-page input[type=text], .index-page input[type=password] {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border: none;
  outline: none !important;
  font-family: Poppins;
  font-size: 1.1429rem;
  font-weight: 500;
  padding: 0.5357rem 0;
  border-bottom: #eaecee 1px solid;
  margin: 2.0714rem 0 0.5rem 0;
  border-radius: 0;
  color: #2f333b;
}

.index-page button {
  width: 100%;
  /* width: 23.4286rem; */
  height: 4rem;
  border-radius: 0.5714rem;
  margin: 0 auto;
  font-size: 1.1429rem;
  font-family: AppleSDGothicNeo-SemiBold;
}

.index-page button.green {
  border: 0;
  background-color: #8CBE54;
}

.index-page button:disabled ,
.index-page button[disabled]
{
  background-color: #9c9c9c !important;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 3rem;
}

.card {
  margin: 0.5rem;
  flex-basis: 100%;
  padding: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

